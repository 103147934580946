.main-body {
  display: grid;
  grid-template-columns: minmax(200px, 25vw) minmax(500px, 50vw) 30px;
  flex: 1;
}

.App {
  background-color: #EAE7DC;
  min-height: 100vh;
  overflow:auto;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}


@media screen and (max-width: 750px){
  .main-body {
    display: flex;
    flex-direction: column;
  }

  main {
    margin: 20px 10px;
  }
}
