.tag-selector-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

}

.flex-item{
    flex: 0;
    width: 70%;
    text-align: center;
}


@media screen and (max-width: 750px){
    .tag-selectors {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 4px;
    }

    .flex-item{
        flex: 1;
        width: 100%;
    }

    #responsive-tag-selector > .btn {
        border-radius: 0.375rem;
        width: auto;
        margin: 0;
    }

    /* #responsive-tag-selector > .btn:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    
    #responsive-tag-selector > .btn ~ .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    #responsive-tag-selector > .btn:not(:first-child) {
        margin-left: -1px;
    }    */

    
}

/* @media screen and (min-device-width: 480px) {

    .tag-selectors {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    #responsive-tag-selector > .btn:not(:last-child) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    #responsive-tag-selector > .btn ~ .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    #responsive-tag-selector > .btn:not(:first-child) {
        margin-top: -1px;
    }   

} */