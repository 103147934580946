.markdown-preview {
    resize: vertical;
    overflow: auto;
    height: 250px;
}

.bg-sepia, .bg-sepia:focus {
    background-color: #EAE7DC;
}

.buttons {
    display: flex;
}