.post{
    border: 2px;
    border-style: solid;
    border-color: #D8C3A5;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
}

a .post{
    min-height: 200px;
    max-height: 30vh;
    background: linear-gradient(180deg, rgba(255,255,255,0) 80%,  #E9807433 100%);
}

a .post:hover {
    border-color: #E98074;
}

.post-body{ 
    flex: 1;
    overflow: hidden;
}

.fs-7 {
    font-size: 10pt;
}



