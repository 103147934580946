.filter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filter-item {
    flex: 1;
    width: 70%;
    margin: 3px;
    max-width: 250px;
}

@media screen and (max-width: 750px){
    .filter {
        flex-direction: row;
        margin: 0 10px;
        flex-wrap: wrap;
    }
  }